import React, { memo, useEffect, useId, useRef, useState } from 'react';
import OutsideWrapper from '../OutsideWrapper'
import Spinner from './Spinner';

const AutoComplete = ({
    label,
    options = [],
    position = "top",
    loading = false,
    optionLabel = (value) => value,
    optionValue = (value) => value,
    showValue = (value) => value,
    onChange = () => '',
    onSelect = () => '',
    clearOnSelect=false,
    createable=false,
    ...props
}) =>  {


    const [isDropdown, setIsDropdown] = useState(false)
    const dropdown = useRef(null)
    const inputRef = useRef(null)
    const id = useId()

    
    useEffect(() => {
        if( dropdown.current && options.length ) {
            dropdown.current?.querySelector(".dropdown-active")?.classList?.remove("dropdown-active")
            
            const index = options.findIndex(a => showValue(a)?.toLowerCase()?.includes(inputRef.current.value?.toLowerCase()))    
            if (index !== -1) {
                dropdown.current.children[index].classList?.add("dropdown-active")
                autoScroll(index)
            }
            
        } else if( dropdown.current ){
            dropdown.current.children[0].classList?.add("dropdown-active")
        }
    }, [ options.length ])


    const eventHandler = (e) => {
        try {
            if (!dropdown || !["ArrowUp", "ArrowDown", "Enter"].includes(e.key)) return
                        
            const activeElem = dropdown.current?.querySelector(".dropdown-active")
            let i = +activeElem?.id?.split('-')[1]
            
            const KEY_CODES = {
                "ArrowUp": scrollUp,
                "ArrowDown": scrollDown,
                "Enter": onEnter
            }

            KEY_CODES[e.key](i)

        } catch (error) { console.error(error) }
    }



    const scrollUp = (i) => {
        if( isNaN(i) ) i = options.length - 1 
        else if( i ){
            dropdown.current.children[i]?.classList?.remove("dropdown-active")
            i -= 1
        }

        autoScroll(i)
        dropdown.current.children[i]?.classList?.add("dropdown-active")
    }



    const scrollDown = (i) => {
        if( isNaN(i) ) i = 0
        else if( i === 0 || i ){
            dropdown.current.children[i]?.classList?.remove("dropdown-active")
            i += 1
        }

        autoScroll(i)
        dropdown.current.children[i]?.classList?.add("dropdown-active")
    }



    const onEnter = (i, e) => {
        if( isNaN(i) ) return handleCreateNew()    
        selectValue(options[i])
    }



    const autoScroll = (i) => {
        dropdown.current.scrollTop = i * dropdown.current.children[0].clientHeight
    }



    const handleChange = (e) => {
        setIsDropdown(!!e.target.value)
        onChange(e.target.value)
    }


    const selectValue = ( value ) => {
        const newvalue = optionValue(value)
        const newshowValue = showValue(value)
        
        if( clearOnSelect ) inputRef.current.value = ''
        else inputRef.current.value = newshowValue
        
        setIsDropdown(false)
        onChange( newvalue )
        onSelect( newvalue )
    }

    const handleCreateNew = () => {   
        const value = inputRef.current.value  

        if( !value ) return 
        else if( clearOnSelect ) inputRef.current.value = ''

        onChange( value ) 
        onSelect( value )
        setIsDropdown( false )
    }

    return (
        <OutsideWrapper callback={() => setIsDropdown(false)}>

            <div className='position-relative' id={id}>

                { (!!options.length || createable) &&  <div className={`bg-white p-2 rounded position-absolute shadow w-100 ${ !isDropdown && "d-none"} `} style={{ [position === "top" ? "bottom" : "top"] : "100%", zIndex: 9 }}>
                    <div id='dropdown' ref={dropdown} >
                        
                        {options.map((value, index) =>
                            <div id={`list-${index}`}
                                className={`p-2 my-1 dropdown-list`}
                                key={index}
                                onClick={() => selectValue(value)}>
                                {optionLabel(value)}
                            </div>
                        )}

                        {
                            !options.length && createable && <p id={`list-create`}
                                className={`p-2 my-1 dropdown-list`}
                                onClick={() => handleCreateNew()}
                            >
                                Create New
                            </p>
                        }

                    </div>
                    <div className='bg-white text-center pt-2'>
                        <Spinner loading={loading} />
                    </div>
                </div>}

                <div className="mb-3">
                    {label&& <label className="form-label">{label}</label>}
                    <input
                        id={id}
                        type='search'
                        autoComplete='off'
                        ref={inputRef}
                        className={`form-control`}
                        onChange={handleChange}
                        onKeyDown={eventHandler}
                        {...props}
                    />
                </div>

            </div>
        </OutsideWrapper>
    );
}

export default memo(AutoComplete);