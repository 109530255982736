import React from "react";
import OffCanvas from "../../../components/OffCanvas";
import CreatePatient from "./Patient";
import AddAppointment from "./Appointment";
import { useDispatch, useSelector } from "react-redux";
import { setPayment, setTab } from "../../../store/reducers/reception";

export default () => {
  const { tab } = useSelector( state => state.reception)
  const dispatch = useDispatch()

  const resetTabs = () => {
    dispatch(setPayment({}))
    dispatch(setTab("patient"))
  }

  return (
    <OffCanvas title={ tab === 'patient' ? "Create Patient" : "Add Appointment" } onClose={resetTabs}>
      {tab === "patient"
        ?  <CreatePatient />
        : <AddAppointment />
      }
    </OffCanvas>
  );
};
