import Avatar from '../assets/images/avatar.jpg';
import Logo from '../assets/images/logo.png';
import LoginBanner from '../assets/images/login-banner.png';
import { faFilePdf, faFileCsv, faImage } from '@fortawesome/free-solid-svg-icons'

export const avatar =  Avatar
export const logo = Logo
export const login_banner = LoginBanner


export const ROLES = {
  "SA": "Super Admin",
  "PT": "Patient",
  "AC": "Accountant",
  "RC": "Reception",
  "DR": "Doctor",
  "JDR": "Junior Doctor",
  "PH": "Pharmacist",
  "IPD": "IPD",
  "NR": "Nurse",
  "LB": "Lab",
  "DA":"Dr Assistant",
  "AD": "Admin"
}


export const medicineTypes = [
    {label:"Injection", value:"INJ", color: "red" },
    {label:"Tablet", value:"TAB", color: "green" },
    {label:"Capsule", value:"CAP", color: "pink" },
    {label:"Syrup", value:"SYP", color: "brown" }, 
    {label:"Cream", value:"CRM" , color: "violet"},
    {label:"Lotion", value:"LT", color: "yellow" },
    {label:"Powder", value:"PW", color: "grey" },
    {label:"Kit", value:"KIT", color: "maroon" },
    {label:"Other", value:"OT", color: "blue" },
]

export const paymentModes = [
    { label: "Cash", value: 'cash' }, 
    { label: "Online", value: "online" },
    { label: "FOC", value: "foc" },
    { label: "Follow Up", value: "followUp" },
]

export const medicineStatus = [
    { label: "In Stock", value: "inStock" },
    { label: "Low Stock", value: "lowStock" },
    { label: "Out of Stock", value: "outOfStock" },
    { label: "Expired", value: "expired" },
  ]



export const firms = [
  "Narayan Medical Care",
  "Shubh SuperSpeciality Center"
]

export const PharmacyFirm = [
  "SANTOSH PHARMACY",
  "SANTOSH PARMACY"
]

export const reportsType = [
  "CT Scan",
  "MRI",
  "Fibre Scan",
  "Blood Test"
]

export const  serviceTypes =[ "OPD", "Lab" ]

export const doseTimming = [ "BD", "OD", "TDS", "QID", "HS", "STAT", "SOS", "ONCE A WEEK", "TWICE A WEEK" ]

export const doseRoute = [ 'I/V', 'I/M',"S/C", 'P/O','T/D',"L/A","S/C", "P/R","P/V", "I/N",'S/L',"I/C" ]

export const doseMeal = ['Before Breakfast', 'After Breakfast','Before Lunch','After Lunch', 'Before Dinner','After Dinner', 'Before Meal','After Meal','Empty Stomach' ,'In b/w Meal', "Any Time"]

export const tabQuantity = [0.5, 1 , 1.5 , 2 , 3]

export const VedSirTabQuantity = ["1--0--0", "0--1--0", "0--0--1", "1--1--1", "1--1--0", "1--0--1", "0--1--1", "1/2--1/2--1",'1/2--0--1','0--0--1/2,']

export const syrupQuantity = ['5ml','10ml','15ml','20ml','25ml','30ml',"50ml", "100ml","150ml", "500ml"]

export const powderQuantity = ['1 tsp', '2 tsp', '3tsp' , '4tsp']

export const INVESTIGATIONS = ['CBC', 'LFT', 'KFT', 'USG WHOLE ABD', 'S.LIPASE', 'HBSAG/ANTI HVC', 'TSH','CECT WHOLE ABD', 'COLONSCOPY', 'SIGMOIDOSCOPY', 'ENDOSCOPY', 'ERCP', 'FORIEGN BODY REMOVEL', 'EVL', 'GLUE INJESTION' ]
  
export const FILE_ICONS = {
  "csv": faFileCsv,
  "pdf": faFilePdf,
  "image": faImage,
  "png": faImage,
  "jpg": faImage,
  "jpeg": faImage,
}
  
  