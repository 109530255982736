import { useCallback, useEffect, useRef, useState } from "react";
import {
  faExpand,
  faBell,
  faBars,
  faAngleLeft,
  faCheck,
  faXmark,
  faPaperPlane
} from "@fortawesome/free-solid-svg-icons";

import Appoinment from "../pages/Reception/Appointment";
import Icon from "../components/Elements/Icon";
import FullScreen from "../service/FullScreenService";

import { useDispatch, useSelector } from "react-redux";
import { offcanvasToggle, toggleMobileSidebar } from "../store/reducers/common";
import { useDebounce } from "../hooks/useDebounce";

import axios from "axios";
import AutoComplete from "../components/Elements/AutoComplete";
import { useNavigate } from "react-router-dom";
import Modal from "../components/Elements/Modal";
import {useNotification} from '../contexts/Notification'
import Spinner from "../components/Elements/Spinner";
import PrimaryBtn from "../components/Elements/PrimaryBtn";
import OutsideWrapper from "../components/OutsideWrapper";

export default () => {
  const dispatch = useDispatch();
  const user = useSelector( state => state.auth.user )
  const navigate = useNavigate()
  const notify = useNotification()
  const [ notification, setNotification ] = useState({ open: false, notifications: [] }) 
  const [ deleteModal, setDeleteModal ] = useState({ open: false, notificationId: null }) 

  const [result, setResult] = useState([]);
  const [refundModal, setRefundModal] = useState({ open: false, data: {} });
  const [isFullScreen, setIsFullScreen] = useState(document.fullscreen);

  useEffect(() => {
    document.onfullscreenchange = (e) => setIsFullScreen(!isFullScreen)
  }, [ isFullScreen ])


  useEffect(() => {
    getAllNotifications()

    window.socket.on("notification", notificationHandler)
    document.addEventListener('keypress', (e) => {
      if( e.ctrlKey && e.code === "KeyQ" ){
        document.getElementById("headerSearch").focus()
      }
    })
  }, [ ])

  const notificationHandler = ( data ) => {
      setNotification(prev => ({ ...prev, notifications: [ data.remark, ...prev.notifications ]}))
  }

  const fullScreenToggle = () => {
    FullScreen();
  };

  const getAllNotifications = async ()=> {
    try {
      const response = await axios.get('/notifications')
      setNotification( prev => ({ ...prev, notifications: response.data?.notifications }))
    } catch (error) {
      console.log(error)
    }
  }

  const [searchPatient, loading] = useDebounce( async (search) => {
      if (!search) {
        setResult([]);
        return;
      }
      const response = await axios.get(`/patients`, {
        params: { search },
      });

      setResult(response.data.patients);
    }, 500, []);

    const options = useCallback((patient) =>
      <div className='d-flex justify-content-between align-items-center'>
        <div className='mb-0 text-dark'>
          <p className="mb-0">{patient.UPID}</p>
          <span className="text-muted f-10">{patient.phone}</span>
        </div>
        <p className="mb-0 f-14">{patient.name}</p>
        <p className="mb-0 f-14">{patient.age}/{patient.gender}</p>
      </div>,
    [result])
 

    const [refundAmount, refunding ] = useDebounce(async () => {
      const response = await axios.post('/accountant/refund-amount', { paymentId: refundModal.data?.paymentId, refundAmount: refundModal.data?.amount, notificationId: refundModal?.notificationId })
      setRefundModal({})
      setNotification({ open: false,  notifications: notification.notifications.filter( n => n._id !== refundModal?.notificationId )})
      notify.success(response.data.message)
    }, 400, [ refundModal, notification ])


    const handleDeleteNotification = async ( e, notificationId ) => {
      try {

        e.stopPropagation()
        if( !deleteModal.notificationId ){
          return setDeleteModal({ open: true, notificationId })
        }


        const response = await axios.delete('notifications', { params: { notificationId }})
        setDeleteModal({ open: false, notificationId: null })
        notify.success( response.data.message )

        setNotification( prev => ({ ...prev, notifications: prev.notifications.filter( n => n._id !== notificationId ) }))

      } catch (error) {
        console.log("error", error)
      }
    }

    return (
    <>
      <header className="pc-header">
        <div className="header-wrapper ">
          <div className="w-100 pc-mob-drp">
            <ul className="list-unstyled w-100">

              <li className="pc-h-item pc-sidebar-popup ">
                <a  className="pc-head-link ms-0" onClick={() => dispatch(toggleMobileSidebar())} >
                  <Icon icon={faBars} />
                </a>
              </li>

              <li className="pc-h-item d-none d-md-inline-flex w-50">

                <div className="col-12 mt-3">
                  <AutoComplete
                    onChange={(value) => searchPatient(value)}
                    loading={loading}
                    options={result}
                    optionValue={({ _id }) => _id}
                    showValue={({ name }) => name}
                    optionLabel={options}
                    onSelect={(e) => navigate(`/patient/${e}`)}
                    placeholder="Search Patients or Ctrl Q"
                    position="bottom"
                    className="form-control  hover-effect"
                    id={"headerSearch"}
                    clearOnSelect={true}
                  />
                </div>
              </li>

              {user.role === "RC" && <li className="pc-h-item d-none-sm">
                <button
                  onClick={() => dispatch(offcanvasToggle())}
                  className="btn btn-primary mx-3"
                >
                  Add Appointment
                </button>
              </li>}

            </ul>
          </div>
          
          <div className="ms-auto">
            <ul className="list-unstyled">
              { window.location.pathname !== '/' && <li className="pc-h-item mx-2">
                <a className={`pc-head-link me-0 hover-effect  border border-1 bg-white`}
                  onClick={() => navigate(-1)}
                  title="Back"
                >
                  <Icon
                    icon={faAngleLeft}
                    className={"text-primary"}
                  />
                </a>
              </li>}

              <li className="pc-h-item mx-2">
                <a
                  className={`pc-head-link me-0 hover-effect  border border-1  ${
                    isFullScreen ? "bg-primary" : "bg-white"
                  }`}
                  onClick={fullScreenToggle}
                  title="Full Screen"
                >
                  <Icon
                    icon={faExpand}
                    className={isFullScreen ? "text-light" : "text-primary"}
                  />
                </a>
              </li>

              <li className="pc-h-item mx-2">

                <OutsideWrapper callback={() => setNotification({ ...notification, open: false })} dependencies={[ notification ]}>
                  <a
                    className="pc-head-link  me-0 bg-white border border-1 hover-effect"
                    title="Notification"
                    onClick={()=> setNotification({ ...notification, open: !notification.open })}
                  >
                    <Icon icon={faBell} className="text-primary" />
                    {notification.notifications?.length > 0 && <span className="position-absolute bg-danger text-light rounded-circle d-flex justify-content-center align-items-center fs-8" style={{ width: "15px", height: "15px", top:"3px", right: "3px"}}>{ notification.notifications?.length }</span>}
                  </a>
                  <div className={`notification shadow no-scrollbar ${ notification.open ? "d-block" : "d-none"}`}>
                    <p className="text-muted p-3 pb-0">Notifications</p>
                    
                    {notification.notifications?.map(( notification, i )=><div className="border-bottom item" key={i} onClick={() => setRefundModal({ open: true, data: notification.data, notificationId: notification._id })}>
                      <div className="d-flex ">
                        <div className=" bg-light-primary rounded d-flex justify-content-center align-items-center icon" style={{ minWidth:"50px", width:"50px", height: "50px"}}>
                          <Icon icon={faBell} className="fs-4" />
                        </div>
                        <div className="ms-3">
                          <div className=" mb-2">
                            <p className="fw-bold mb-0">{ notification.data?.type } | { notification.data?.patient?.name }</p>
                            <p className="mb-0" style={{ fontSize: "10px"}}>{ new Date(notification?.createdAt).toLocaleString()}</p>
                          </div>
                          <p className="fs-8 mb-0">{ notification.data?.amount ? "₹ "+notification.data?.amount : null } | { notification.data?.note }</p>
                        </div>
                      </div>
                      <div className="options" onClick={ e => handleDeleteNotification(e, notification._id )}>
                        {/* <span className="text-success me-3 cursor-pointer"><Icon icon={faCheck} className="ms-2 " /> Seen</span> */}
                        <span className="text-danger cursor-pointer"><Icon icon={faXmark} className="ms-2 " /> Delete</span>
                      </div>
                    </div>)}

                  </div>
                </OutsideWrapper>
              </li>
            </ul>
          </div>
        </div>
      </header>

      { user?.role === "RC" && <Appoinment />}

      { refundModal.open && <Modal open={refundModal.open} setOpen={setRefundModal} title="Amount Refund">
        <div className='d-flex mb-3'>
          <p className='mb-0 me-3'><span className='fw-bold me-2'>UPID:</span>{ refundModal.data?.patient?.UPID }</p>
          <p className='mb-0 me-3'><span className='fw-bold me-2'>Name:</span>{ refundModal.data?.patient?.name }</p>
          <p className='mb-0 me-3'><span className='fw-bold me-2'>Age:</span>{ refundModal.data?.patient?.age }</p>
          <p className='mb-0 me-3'><span className='fw-bold me-2'>Gender:</span>{ refundModal.data?.patient?.gender }</p>
        </div>

        <div className="d-flex align-items-center mt-4">
          <div className="w-75">
            <input className='borderless p-2 bg-light rounded w-100 border' placeholder='Amount' onChange={e => setRefundModal({ ...refundAmount, data: { ...refundModal.data, amount: e.target.value} })} defaultValue={ refundModal.data?.amount }/>
          </div>
          <div className='w-25 text-end'>
              <span className='py-2 px-3 bg-light-success border  border-success rounded-3 fs-4 cursor-pointer' onClick={refundAmount}>{refunding ? <Spinner loading={refunding} /> : <Icon icon={faPaperPlane} />}</span>
          </div>
        </div>
      </Modal>}


      { deleteModal.open && 
        <Modal title="Delete Notification" open={deleteModal.open} setOpen={setDeleteModal} >
          <p className="text-danger">Do you want to delete Notification ?</p>

          <div className="d-flex justify-content-between mt-4">
            <PrimaryBtn className='w-100 btn btn-light mx-2 rounded border border-2' onClick={() => setDeleteModal({ open: false, data: null })}>Cancel</PrimaryBtn>
            <PrimaryBtn className='w-100 btn btn-danger mx-2 rounded' onClick={ e => handleDeleteNotification(e, deleteModal.notificationId)}>Delete</PrimaryBtn>
          </div>
        </Modal>
      }
    </>
  );
};
