import { useDispatch, useSelector } from "react-redux"
import { offcanvasToggle } from "../store/reducers/common"
import { useEffect } from "react"

export default ({ title, children, onClose = () => '' }) => {
    const { offcanvas } = useSelector(state => state.common)
    const dispatch = useDispatch()

    useEffect(() => {
        if( !offcanvas ) onClose()
    }, [ offcanvas ])

    return (
        <>
            <div
                className={`offcanvas offcanvas-end slide-animate bg-light ${offcanvas ? "show " : ""}`}
                tabIndex={-1}
            >
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title">
                        {title}
                    </h5>
                    <button
                        type="button"
                        className="btn-close text-reset"
                        onClick={() => { onClose(); dispatch(offcanvasToggle()) }}
                    />
                </div>
                <div className="offcanvas-body px-4">
                    {children}
                </div>
            </div>

            {offcanvas && <div className={`offcanvas-backdrop fade show `} />}
        </>
    )
}