import React from "react";

const InputBox = ({label, type='text', maxLength, numberOnly = false, placeholder, register = {}, ...props}) => {
  return (
      <div className="mb-3">
        {label && <label className="form-label">{label}</label>}
        <input
          type={type}
          className="form-control"     
          placeholder={placeholder}
          maxLength={maxLength}
          onInput={(e) => numberOnly && (e.target.value = e.target.value.replace(/[^0-9]/g, "")) }
          { ...register }
          {...props}

        />
    </div>
  );
};

export default InputBox;
