import { createSlice } from "@reduxjs/toolkit"


const commonSlice = createSlice({
    name: "common",

    initialState: {
        offcanvas: false,
        offcanvasData: {},
        mobileSidebar: false,
        isOnline: window.navigator.onLine
    },

    reducers: {
        
        offcanvasToggle: ( state, action ) => {
            state.offcanvas = !state.offcanvas
            state.offcanvasData = state.offcanvas ? action.payload : {}
        },

        toggleMobileSidebar: ( state, action ) => {
            state.mobileSidebar = !state.mobileSidebar
        },

        setIsOnline: ( state, action ) => {
            state.isOnline = action.payload
        }

    }
})

export const { offcanvasToggle, toggleMobileSidebar, setIsOnline } = commonSlice.actions;
export default commonSlice.reducer;