import InputBox from "../../../components/Elements/InputBox";
import Spinner from '../../../components/Elements/Spinner';
import ErrorMessage from '../../../components/Elements/ErrorMessage';

import DataList from "../../../components/Elements/DataList";
import { Controller, useForm } from "react-hook-form";
import Select from "../../../components/Elements/Select";

import axios from "../../../axios";
import { useDebounce } from '../../../hooks/useDebounce';

import { useDispatch } from "react-redux";
import { setPatient, setTab } from "../../../store/reducers/reception"; 
import AutoComplete from "../../../components/Elements/AutoComplete";
import { useCallback, useState } from "react";

const Appointment = () => {
  const dispatch = useDispatch()
  const [patients,setPatients] = useState([])
  
  const { handleSubmit, register, watch, control } = useForm({
    onChange: true,
    defaultValues: { appointmentType: "new" },
  });


  const [ onSubmit, isSubmitLoading, error ] = useDebounce(async (data) => {
      const response = await axios.post("reception/patient", data )
      dispatch(setPatient(response.data.patient))
      dispatch(setTab("appointment"))
  })


  const [searchPatient, loading] = useDebounce(async (search) => {
      if (!search) return setPatients([]);
  
      const response = await axios.get(`/patients`, { params: { search } });
      setPatients(response.data.patients|| []);
    },
  500, []);


  const optionLabel = useCallback(( patient ) =>
    <div className='d-flex justify-content-between align-items-center'>
      <div className='mb-0 text-secondary'>
        <p className="mb-0">{patient.name}</p>
      </div>
      <span className="mb-0 f-14 text-secondary">{patient.phone}</span>
    </div>,
    [ patients ])

    const handleSelect = ( patient, key ) => {
      dispatch(setPatient(patients.find(p => p[key] === patient)))
      dispatch(setTab("appointment"))
    }

  return (
    <div className="row mb-5">
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">

        <div className="col-md-12">
            <Controller
              name="name"
              control={control}
              rules={{ required: "Patient name is required." }}
              render={({ field: { onChange, onBlur, value, ref },  fieldState: { error } }) => (
                <>
                  <AutoComplete
                    ref={ref}
                    onChange={(v) => (onChange(v), searchPatient(v))}
                    onBlur={onBlur}
                    value={value}
                    label={"Patient Name *"}
                    placeholder="Patient Name"
                    className="form-control"
                    position="bottom"
                    options={patients}
                    optionLabel={optionLabel}
                    optionValue={({ name }) => name}
                    showValue={({name}) => name}
                    onSelect={e => handleSelect(e, "name")}
                    autoFocus
                  />  
                  {error && <p className="text-danger">{error.message}</p>}
                </>
              )}
            />
        </div>
        <div className="col-md-12">


          <Controller
              name="phone"
              control={control}
              rules={{ required: "Phone is required." }}
              render={({ field: { onChange, onBlur, value, ref },  fieldState: { error } }) => (
                <>
                  <AutoComplete
                    ref={ref}
                    onChange={(v) => (onChange(v), searchPatient(v))}
                    onBlur={onBlur}
                    value={value}
                    label={"Mobile Number *"}
                    placeholder="Mobile Number"
                    className="form-control"
                    position="bottom"
                    options={patients}
                    optionLabel={optionLabel}
                    optionValue={({ phone }) => phone}
                    showValue={({phone}) => phone}
                    maxLength={10}
                    onInput={e => e.target.value = e.target.value.replace(/[^0-9]/g, "")}
                    onSelect={e => handleSelect(e, "phone")}

                  />
                  {error && <p className="text-danger">{error.message}</p>}
                </>
              )}
            />
        </div>
        <div className="col-md-12 mb-3">
          <div>
            <h6 className="my-3">Address</h6>
            <div className="col-md-12">
              <InputBox label={"Area"} placeholder={"Area"} register={register('address.area', { required: true })} />
            </div>
            <DataList list={['Aligarh', 'Mumbai']} label={"City *"} placeholder={"City"} register={register('address.city', { required: true })} />
          </div>
        </div>
        <div className="row">

          <div className="col-md-6">
            <InputBox label={"Age *"} placeholder={"Age"} numberOnly={true} maxLength={2} register={register('age', { required: true })} />
          </div>
          <div className="col-md-6">
            <Select label={'Gender'} list={[{ label: "Male", value: 'M' }, { label: "Female", value: "F" }, { label: "Other", value: "O" }]} register={register('gender')} />
          </div>
        </div>


        <div className="col-md-12 my-3">
          <ErrorMessage error={error} />
          <button className="btn btn-primary w-100 rounded" type="submit" disabled={isSubmitLoading}>
            <Spinner loading={isSubmitLoading} >
              Create Patient
            </Spinner>
          </button>
        </div>
      </form>
    </div>
  );
};

export default Appointment;
